import { AxiosError } from 'axios';
import { Timestamp, DocumentReference, deleteField, FieldValue } from 'firebase/firestore';
import { ExportConfig } from 'models/ExportConfig';
import { FsImportConfig } from 'models/ImportConfig';
import { ValidationMessages } from '../models/ValidationErrorMessage';

export const getErrorMessages = (error?: AxiosError): string => {
  const m = error?.response?.data as unknown as ValidationMessages;
  return m?.message.toString() ?? 'An error occurred while processing the request.';
};

export const utcToLocalDayAndTime = (utcTimestamp?: string) => {
  if (utcTimestamp) {
    const d = new Date(utcTimestamp);
    return `${d.toLocaleDateString()} ${d.toLocaleTimeString()}`;
  } else {
    return 'NA';
  }
};

export const convertToLocalDayAndTime = (dateTime?: Timestamp) => {
  if (dateTime) {
    return `${dateTime.toDate().toLocaleDateString()} ${dateTime.toDate().toLocaleTimeString()}`;
  } else {
    return 'NA';
  }
};

export const isStacked = (importConfig: FsImportConfig | null) => {
  return importConfig?.filesConfig?.strategy === 'stack';
};

export const getFileList = (importConfig: FsImportConfig | null): Array<{ name: string }> | [] => {
  if (importConfig?.filesConfig?.tables) {
    const tableAliases = importConfig?.filesConfig?.tables.map((x) => ({ name: x.alias }));
    const filesMeta = importConfig.filesMeta
      ? Object.values(importConfig.filesMeta).map((x) => ({ name: x.name }))
      : [];
    return [...tableAliases, ...filesMeta];
  }
  return Object.values(importConfig?.filesMeta || {}).map((x) => ({ name: x.name })) || [];
};

export const getColumnsForFile = (
  importConfig: FsImportConfig | null,
  hasCustomHeaders: boolean,
  fileName?: string,
  isSampleData?: boolean,
): string[] | undefined => {
  if (hasCustomHeaders) {
    if (isSampleData) {
      return importConfig?.filesConfig?.columns;
    }
    return importConfig?.filesConfig?.columns?.filter((x) => !!x);
  }
  if (isStacked(importConfig)) {
    if (importConfig?.filesMeta) return Object.values(importConfig?.filesMeta)[0].columns.filter((x) => !!x);
  }
  const fileMeta = Object.values(importConfig?.filesMeta || {}).find((x) => x.name === fileName);
  if (fileMeta) {
    return fileMeta.columns.filter((x) => !!x);
  } else if (importConfig?.filesConfig?.tables) {
    const tableAliasConfig = importConfig?.filesConfig?.tables.find((x) => x.alias === fileName);
    const fileMeta = Object.values(importConfig?.filesMeta || {}).find((x) => x.name === tableAliasConfig?.sourceTable);
    if (fileMeta) {
      return fileMeta.columns.filter((x) => !!x);
    }
  }
  return [];
};

export const assessmentTypes = [
  ['dessa_hse_ssr', 'DESSA HSE SSR'],
  ['dessa_mini_1', 'DESSA Mini 1'],
  ['dessa_mini_2', 'DESSA Mini 2'],
  ['dessa_mini_3', 'DESSA Mini 3'],
  ['dessa_mini_4', 'DESSA Mini 4'],
  ['dessa_hse_mini_1', 'DESSA HSE Mini 1'],
  ['dessa_hse_mini_2', 'DESSA HSE Mini 2'],
  ['dessa_hse_mini_3', 'DESSA HSE Mini 3'],
  ['dessa_hse_mini_4', 'DESSA HSE Mini 4'],
  ['dessa', 'DESSA'],
  ['dessa_sse', 'DESSA SSE'],
  ['dessa_40', 'DESSA 40'],
  ['dessa_hse', 'DESSA HSE'],
  ['dessa_2', 'DESSA 2'],
  ['dessa_2_mini_1', 'DESSA 2 Mini Form A'],
  ['dessa_2_mini_2', 'DESSA 2 Mini Form B'],
  ['dessa_2_mini_3', 'DESSA 2 Mini Form C'],
  ['dessa_2_mini_4', 'DESSA 2 Mini Form D'],
].map(([value, label]) => ({ label, value }));

export const getPausedStatus = (exportConfig?: ExportConfig) => {
  if (!exportConfig) return false;
  if (exportConfig.pausedAt && exportConfig.lastSuccessfulRunAt) {
    return exportConfig.pausedAt.seconds > exportConfig.lastSuccessfulRunAt.seconds;
  } else if (exportConfig.pausedAt && !exportConfig.lastSuccessfulRunAt && !exportConfig.lastRun) {
    return true;
  } else if (!exportConfig.pausedAt && (exportConfig.lastSuccessfulRunAt || exportConfig.lastRun)) {
    return false;
  }
};

const getOrdinalSuffix = (num: number) => {
  return num >= 0 ? ['th', 'st', 'nd', 'rd'][(num > 3 && num < 21) || num % 10 > 3 ? 0 : num % 10] : '';
};

export const getDayStringFromNumericValue = (type: string, num?: number | null) => {
  const dayOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  if (num == null) return '--';
  if (type === 'WEEKLY') {
    return dayOfWeek[num];
  } else {
    return `${num}${getOrdinalSuffix(num)}`;
  }
};

export const dayOfWeekOptions = [
  {
    value: 0,
    label: 'Sunday',
  },
  {
    value: 1,
    label: 'Monday',
  },
  {
    value: 2,
    label: 'Tuesday',
  },
  {
    value: 3,
    label: 'Wednesday',
  },
  {
    value: 4,
    label: 'Thursday',
  },
  {
    value: 5,
    label: 'Friday',
  },
  {
    value: 6,
    label: 'Saturday',
  },
];

export const dayOfMonthOptions = [
  {
    value: 1,
    label: '1st',
  },
  {
    value: 2,
    label: '2nd',
  },
  {
    value: 3,
    label: '3rd',
  },
  {
    value: 4,
    label: '4th',
  },
  {
    value: 5,
    label: '5th',
  },
  {
    value: 6,
    label: '6th',
  },
  {
    value: 7,
    label: '7th',
  },
  {
    value: 8,
    label: '8th',
  },
  {
    value: 9,
    label: '9th',
  },
  {
    value: 10,
    label: '10th',
  },
  {
    value: 11,
    label: '11th',
  },
  {
    value: 12,
    label: '12th',
  },
  {
    value: 13,
    label: '13th',
  },
  {
    value: 14,
    label: '14th',
  },
  {
    value: 15,
    label: '15th',
  },
  {
    value: 16,
    label: '16th',
  },
  {
    value: 17,
    label: '17th',
  },
  {
    value: 18,
    label: '18th',
  },
  {
    value: 19,
    label: '19th',
  },
  {
    value: 20,
    label: '20th',
  },
  {
    value: 21,
    label: '21st',
  },
  {
    value: 22,
    label: '22nd',
  },
  {
    value: 23,
    label: '23rd',
  },
  {
    value: 24,
    label: '24th',
  },
  {
    value: 25,
    label: '25th',
  },
  {
    value: 26,
    label: '26th',
  },
  {
    value: 27,
    label: '27th',
  },
  {
    value: 28,
    label: '28th',
  },
];
export type FirestoreChangeType = 'create' | 'update' | 'delete';

export class FirestoreUtil {
  /**
   * Flatten a nested object to only be one level where properties
   * are in dot-notation format. This is needed to make updates in
   * firebase to nested properties without replacing the entire nested object.
   * @param o
   */
  public static toDotNotation(o: Record<string, unknown> | null): Record<string, unknown> {
    const map = {} as Record<string, unknown>;
    if (o == null) return map;
    for (const prop in o as object) {
      // eslint-disable-next-line no-prototype-builtins
      if (!o.hasOwnProperty(prop)) continue;
      const value = o[prop];
      if (value instanceof FieldValue || value instanceof Timestamp || value instanceof DocumentReference) {
        map[prop] = value;
      } else if (value == null) {
        map[prop] = deleteField();
      } else if (Array.isArray(value)) {
        // You can't patch an array. You have to set the whole thing or use a FieldValue.
        map[prop] = value;
      } else if (value instanceof Object) {
        const subMap = this.toDotNotation(value as Record<string, unknown>);
        // eslint-disable-next-line guard-for-in
        for (const subProp in subMap) {
          const subValue = subMap[subProp];
          map[`${prop}.${subProp}`] = subValue;
        }
      } else {
        map[prop] = value;
      }
    }
    return map;
  }
}
